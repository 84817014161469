<template>
  <div class="auth-container">
    <div class="modal-login-title">Введите email</div>
    <div class="modal-login-subtitle">Нужен для кода подтверждения</div>
    <v-text-field
      v-model="email"
      @input="emailError = ''"
      hide-details="auto"
      :error-messages="emailError"
      label="Email"
      persistent-placeholder
      class="auth-input mb-7"
      height="50"
      autofocus
      type="email"
      name="email"
    />
    <v-btn depressed :ripple="false" color="primary" width="100%" @click="next" :disabled="!checkValue" :loading="loading" class="mb-6">Далее</v-btn>
    <LicenseAgreement buttonText="Далее"/>
    <div class="transparent-button" @click="toLoginPhone">Войти через телефон</div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import LicenseAgreement from '@/views/login/LicenseAgreement.vue';

export default {
  components: {
    LicenseAgreement,
    Preloader,
  },
  name: 'LoginEmail',
  data() {
    return {
      email: '',
      emailError: '',
      loading: false,
    };
  },
  methods: {
    toLoginPhone() {
      this.$router.push({
        path: '/login/phone',
        query: {
          role: 'hr',
          url: this.$route.query.url || '',
        },
      });
    },

    next() {
      if (this.checkValue) {
        this.loading = true;
        this.$server.request2('auth/login', { email: this.email, role: this.$route.query.role, }, (data)=>{
          const codeToken = data.response;
          this.$router.push({
            path: '/login/code',
            query: {
              codeToken,
              email: this.email,
            },
          });
          this.loading = false;
        }, (data)=>{
          this.loading = false;
          this.emailError = data.error;
        })
      }
    },
  },
  computed: {
    checkValue() {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      return reg.test(this.email);
    },
  },
};
</script>

<style scoped>

</style>
